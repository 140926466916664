var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"details"},[_c('div',{staticClass:"content_top"},[_c('div',{staticClass:"images"},[_c('div',{staticClass:"map_image"},[_c('img',{attrs:{"src":_vm.mapimg
              ? _vm.mapimg
              : _vm.baseUrl + 'third/third/ossGetFile/' + _vm.content.mainGraph,"alt":""}})]),_c('div',{staticClass:"min_image"},_vm._l((_vm.minimg),function(item,index){return _c('img',{key:index,attrs:{"src":_vm.baseUrl + 'third/third/ossGetFile/' + item,"alt":""},on:{"mouseover":function($event){return _vm.mouseOver(item)},"mouseleave":_vm.mouseLeave}})}),0)]),_c('div',{staticClass:"content_righ"},[_c('div',{staticClass:"message"},[_c('div',[_vm._v(" "+_vm._s(_vm.content.name)+" ")]),_c('div',{staticClass:"serveType"},[_vm._v("服务类别："+_vm._s(_vm.content.serviceType))])]),_c('div',{staticClass:"mony"},[_c('div',{staticClass:"mony_type"},[_vm._v("价格")]),_c('div',{staticClass:"mony_num"},[(_vm.content.price)?_c('span',{staticClass:"mony_icon"},[_vm._v("￥")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.content.price ? _vm.content.price : "面议")+" "),_c('span',[_vm._v(_vm._s(_vm.content.unit))])])])]),_c('div',{staticClass:"information"},[_c('div',[_c('div',[_vm._v("联系方式:")]),_c('div',{staticClass:"information_item"},[_c('span',[_vm._v(_vm._s(_vm.content.concateUser))]),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.content.phone))])])]),_c('div',[_c('div',[_vm._v("商家地址:")]),_c('div',{staticClass:"information_item"},[_vm._v(" "+_vm._s(_vm.content.address)+" ")])]),_c('div',[_c('div',[_vm._v("服务商名称")]),_c('div',{staticClass:"information_item"},[_vm._v(_vm._s(_vm.content.name))])])]),(_vm.content.companyId != _vm.companyId)?_c('div',{staticClass:"details_btn"},[(!_vm.content.companyIfCollection)?_c('el-button',{attrs:{"icon":"hy-icon-chakantieziguanzhu"},on:{"click":function($event){return _vm.enshrine(true)}}},[_vm._v("关注")]):_vm._e(),(_vm.content.companyIfCollection)?_c('el-button',{attrs:{"icon":"hy-icon-guanzhu"},on:{"click":function($event){return _vm.enshrine(false)}}},[_vm._v("取消关注")]):_vm._e(),(
            _vm.content.serviceType != '物流服务' &&
            _vm.content.serviceType != '金融服务'
          )?_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.immediately}},[_vm._v("立即下单")]):_vm._e()],1):_vm._e()])]),_c('div',{staticClass:"serve_detailes"},[_vm._m(0),_c('div',{staticClass:"serve_img",domProps:{"innerHTML":_vm._s(_vm.content.content)}})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"serve_con"},[_c('div',{staticClass:"serve_color"}),_c('div',{staticClass:"serve_ti"},[_vm._v("服务详情")])])
}]

export { render, staticRenderFns }